import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';
import RegularAd from './RegularAd';
import NativeAd from './NativeAd';
import { getPlacement } from '../../../utils/ads/adsUtils';
import { safeWindow } from '../../../utils/listingHelper';

const AdSlot = ({ adParams, adSlot, pageAdReload, enableAdRefresh }) => {
  if (Object.keys(adParams || {}).length === 0 || adParams[adSlot]?.placement?.length === 0) {
    return null;
  }
  if (!('document' in safeWindow())) {
    return <div id={`ad-${adSlot}`} className={adParams[adSlot]?.classes}></div>;
  }
  const placement = adParams[adSlot]?.placement;
  const { adPlacement, isNativeAd } = getPlacement({ placement, pageAdReload, enableAdRefresh });

  return isNativeAd ?
    <NativeAd adParams={adParams} adSlot={adSlot} adPlacement={adPlacement} /> :
    <RegularAd adParams={adParams} adSlot={adSlot} adPlacement={adPlacement} />;
};

AdSlot.propTypes = {
  adParams: PropTypes.object,
  adSlot: PropTypes.string,
  pageAdReload: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string
  ]),
  enableAdRefresh: PropTypes.bool
};

export default AdSlot;
